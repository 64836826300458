import styled from "styled-components";

const SeparatorComponent = styled.hr`
  margin: 0;
  padding: 0;
  border: none;
  height: 3px;
  color: ${(prop) => prop.color};
`;

export const Separator = ({ color, style = {} }) => {
  return <SeparatorComponent color={color} style={style} />;
};
export default {};
