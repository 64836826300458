/* eslint-disable jsx-a11y/label-has-associated-control */
import styled from "styled-components";
import { useState } from "react";
import {
  Radio,
  HorizontalInput,
  Other,
  VerticalInput,
  Checkbox,
} from "../../components/input";
import { Separator } from "../../components/separator";
import { blue, red } from "../../components/globalStyles";
import whatsapp from "../../assets/imgs/whatsapp.svg";
import email from "../../assets/imgs/email.svg";
import instagram from "../../assets/imgs/instagram.svg";
import location from "../../assets/imgs/location.svg";
import topBkg from "../../assets/imgs/heading.svg";
import botBkg from "../../assets/imgs/bottom.svg";
import topBkgResponsive from "../../assets/imgs/heading-responsive.svg";
import whatsappResponsive from "../../assets/imgs/whatsapp-responsive.png";
import emailResponsive from "../../assets/imgs/email-responsive.png";
import instagramResponsive from "../../assets/imgs/instagram-responsive.png";

import {
  condicionLocal,
  documentacion,
  rubros,
  tipoDueno,
  tipoPersona,
  ubicacionLocal,
  formNames,
} from "./info";
import "../../assets/styles.css";
import { Social } from "../../components/social";

const SubmitButton = styled.button`
  margin: 30px 0;
  padding: 10px;
  width: 100%;
  color: white;
  background-color: ${red};
  border-style: none;
  font-family: "MyriadPro";
  font-size: 20px;

  @media (max-width: 767px) {
    font-size: 17px;
    margin: 10px 0;
  }
`;

const Top = styled.div`
  background-image: url(${topBkg});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center 0;
  height: 400px;
  text-align: right;
  padding-top: 45px;
  padding-right: 55px;
  margin-bottom: -100px;
  color: ${blue};
  font-family: "MyriadPro";

  @media (max-width: 767px) {
    background-image: url(${topBkgResponsive});
    height: 220px;
    margin-bottom: -60px;
    padding: 25px 15px 0 100px;
  }
`;

const Bottom = styled.div`
  background-image: url(${botBkg});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center 0;
  height: 110px;
  padding-top: 0;
  padding-right: 60px;
  padding-left: 60px;

  @media (max-width: 767px) {
    background-image: none;
    background-color: ${blue};
    padding: 0;
    height: auto;
  }
`;

const Main = styled.div`
  display: grid;
  grid-template-areas: ". container .";
  grid-template-columns: auto 1000px auto;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-template-areas: "container";
  }
`;

const Center = styled.div`
  grid-area: container;
`;

const Title = styled.h3`
  font-family: "MyriadProBold";
  color: ${red};
  font-size: 22px;
  margin-top: 10px;
`;

const Span = styled.span`
  font-family: "MyriadProLight";
  margin: 0;
  padding: 0;
  color: ${red};
  font-size: 15px;
  margin-left: 10px;

  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

const P = styled.p`
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
`;

const Container = styled.div`
  display: grid;
  grid-template-areas: "left right";
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  padding: 0 60px;
  font-family: "MyriadPro";
  font-size: 20px;
  color: ${blue};

  @media (max-width: 767px) {
    grid-template-columns: auto;
    grid-template-areas: "left" "right";
    padding: 0 20px;
    font-size: 15px;
    grid-gap: 0px;
  }
`;

const SocialCotainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 2.5fr 1fr;
  margin-top: 20px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
    padding: 20px 0;
  }
`;

const Right = styled.div`
  grid-area: right;

  @media (max-width: 767px) {
    margin-top: 10px;
  }
`;
const Left = styled.div`
  grid-area: left;
`;

const Owner = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
`;

const Person = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const Blueprints = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "left right";
`;
const YesNo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "other other";
`;

const TitleWithSeparatorContainer = styled.div`
  padding: 0 60px;
  margin: 20px 0;

  @media (max-width: 767px) {
    padding: 0 20px;
    margin: 20px 0;
  }
`;

const TitleWithSeparator = ({ text }) => (
  <TitleWithSeparatorContainer>
    <Separator color={red} />
    <Title>{text}</Title>
  </TitleWithSeparatorContainer>
);

const HabilitacionComercial = () => {
  const [rubroCheck, setRubroCheck] = useState();
  const [personCheck, setPersonCheck] = useState();
  const [conditionCheck, setConditionCheck] = useState();
  const [duenoCheck, setDuenoCheck] = useState();
  const [planesCheck, setPlanesCheck] = useState();
  const [locationCheck, setLocationCheck] = useState();

  return (
    <>
      <form action={formNames.action} method="POST">
        <Main>
          <Center>
            <Top>
              <p style={{ margin: 0 }}>
                Formulario de información básica necesaria para consulta de
                habilitación de locales comerciales
              </p>
            </Top>
            <Container>
              <Left>
                <VerticalInput
                  label="Nombre y Apellido:"
                  name={formNames.nombreApellido}
                  required
                />
                <Person>
                  {tipoPersona.map((tp, i) => (
                    <Radio
                      required
                      label={tp.label}
                      key={tp.label}
                      name={formNames.tipoPersona}
                      checked={personCheck === i}
                      onChange={() => {
                        setPersonCheck(i);
                      }}
                    />
                  ))}
                </Person>
              </Left>
              <Right>
                <VerticalInput
                  label="Mail de contacto:"
                  name={formNames.mailContacto}
                  required
                />
                <VerticalInput
                  label="Número de contacto:"
                  name={formNames.numeroContacto}
                  required
                />
              </Right>
            </Container>
            <TitleWithSeparator text="DATOS DEL LOCAL" />

            <Container>
              <Left>
                <VerticalInput
                  label="Barrio"
                  name={formNames.barrio}
                  required
                />
                <VerticalInput
                  label="Direccion"
                  name={formNames.direccion}
                  required
                />
                <div>
                  <P>Rubro a habilitar: *</P>
                  {rubros.map((rubro, i) => (
                    <Radio
                      required
                      label={rubro.label}
                      key={rubro.label}
                      name={formNames.rubro}
                      checked={rubroCheck === i}
                      onChange={() => {
                        setRubroCheck(i);
                      }}
                    />
                  ))}
                  <Other
                    name={formNames.rubro}
                    checked={rubroCheck === "otherRubro"}
                    onChange={() => {
                      setRubroCheck("otherRubro");
                    }}
                  />
                </div>
                <Owner>
                  {tipoDueno.map((td, i) => (
                    <Radio
                      label={td.label}
                      key={td.label}
                      name={formNames.tipoDueno}
                      checked={duenoCheck === i}
                      onChange={() => {
                        setDuenoCheck(i);
                      }}
                    />
                  ))}
                </Owner>
              </Left>
              <Right>
                <div>
                  <P>
                    Condición del local:{" "}
                    <Span>Según dónde opere el local comercial</Span>
                  </P>
                  {condicionLocal.map((cl, i) => (
                    <Radio
                      label={cl.label}
                      key={cl.label}
                      name={formNames.condicionLocal}
                      checked={conditionCheck === i}
                      onChange={() => {
                        setConditionCheck(i);
                      }}
                    />
                  ))}
                  <Other
                    name={formNames.condicionLocal}
                    checked={conditionCheck === "otherCondicion"}
                    onChange={() => {
                      setConditionCheck("otherCondicion");
                    }}
                  />
                </div>
                <div style={{ margin: "20px 0" }}>
                  <P>Posee planos del local *</P>
                  <Blueprints>
                    <Left>
                      <YesNo>
                        <Radio
                          label="Si"
                          name={formNames.poseePlanos}
                          checked={planesCheck === "si"}
                          onChange={() => {
                            setPlanesCheck("si");
                          }}
                        />
                        <Radio
                          label="No"
                          name={formNames.poseePlanos}
                          checked={planesCheck === "no"}
                          onChange={() => {
                            setPlanesCheck("no");
                          }}
                        />
                      </YesNo>
                      <YesNo>
                        <div style={{ gridArea: "other" }}>
                          <Other
                            name={formNames.poseePlanos}
                            inputStyle={{ flex: 1.5 }}
                            infoStyle={{ flex: 1 }}
                            checked={planesCheck === "otherPlanes"}
                            onChange={() => {
                              setPlanesCheck("otherPlanes");
                            }}
                          />
                        </div>
                      </YesNo>
                    </Left>
                    <Right>
                      <HorizontalInput
                        label="mts2"
                        name={formNames.mts2}
                        required
                      />
                    </Right>
                  </Blueprints>
                </div>
                <div>
                  <P>Ubicación del local / Pisos que utiliza:</P>
                  {ubicacionLocal.map((ul, i) => (
                    <Radio
                      label={ul.label}
                      key={ul.label}
                      name={formNames.ubicacionLocal}
                      checked={locationCheck === i}
                      onChange={() => {
                        setLocationCheck(i);
                      }}
                    />
                  ))}
                  <Other
                    name={formNames.ubicacionLocal}
                    checked={locationCheck === "otherLocation"}
                    onChange={() => {
                      setLocationCheck("otherLocation");
                    }}
                  />
                  <div style={{ textAlign: "right", marginTop: -10 }}>
                    <Span>De tener más pisos puede aclararlo aquí.</Span>
                  </div>
                </div>
                <div />
              </Right>
            </Container>
            <TitleWithSeparator text="DOCUMENTACIÓN A PROVEER POR EL COMITENTE" />
            <Container>
              <Left>
                {documentacion.left.map((d) => (
                  <Checkbox
                    label={d.label}
                    key={d.label}
                    name={formNames.documentacion}
                  />
                ))}
              </Left>
              <Right>
                {documentacion.right.map((d) => (
                  <Checkbox
                    label={d.label}
                    key={d.label}
                    name={formNames.documentacion}
                  />
                ))}
                <SubmitButton type="submit">Aceptar</SubmitButton>
              </Right>
            </Container>
            <Bottom style={{ marginTop: 20 }}>
              {window.innerWidth > 768 && <Separator color={red} />}
              <SocialCotainer>
                <Social
                  href="https://www.instagram.com/ciudadintegralsoluciones/"
                  src={
                    window.innerWidth > 768 ? instagram : instagramResponsive
                  }
                  text="@CIUDADINTEGRALSOLUCIONES"
                  index
                />
                {window.innerWidth > 768 && (
                  <Social
                    href="https://goo.gl/maps/swW4Muy3GnRFV29v5"
                    src={location}
                    text="AV. CORRIENTES 848, 3 306, CABA"
                    index
                  />
                )}
                <Social
                  href="mailto:SOLUCIONES@CIUDADINTEGRAL.COM.AR"
                  src={window.innerWidth > 768 ? email : emailResponsive}
                  text="SOLUCIONES@CIUDADINTEGRAL.COM.AR"
                  index
                />
                <Social
                  href="https://wa.me/5491138069769"
                  src={window.innerWidth > 768 ? whatsapp : whatsappResponsive}
                  text="11-3806-9769"
                  index
                />
              </SocialCotainer>
            </Bottom>
          </Center>
        </Main>
      </form>
    </>
  );
};

export default HabilitacionComercial;
