import { useEffect, useRef } from "react";
import styled from "styled-components";
import { blue, red } from "./globalStyles";

const isCheckbox = ({ type }) => type === "radio" || type === "checkbox";

const Input = styled.input`
  border: 1px solid ${(p) => p.color};
  width: ${(p) => !isCheckbox(p) && "100%"};
  ${(p) => isCheckbox(p) && "transform : scale(2);"}
  ${(p) => isCheckbox(p) && "margin-right: 15px;"}
  ${(p) => !isCheckbox(p) && "margin-top: -1px;"}
  font-family: "MyriadPro";
  font-size: 20px;
  color: ${red};
  letter-spacing: 1px;

  @media (max-width: 767px) {
    font-size: 15px;
    ${(p) => isCheckbox(p) && "transform : scale(1.5);"}
    ${(p) => isCheckbox(p) && "margin-right: 10px;"}
  }
`;

const Container = styled.div`
  margin-bottom: 15px;
  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const ContainerHorizontal = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
`;

/* eslint-disable jsx-a11y/label-has-associated-control */
export const VerticalInput = ({
  name,
  label,
  className = "",
  type = "text",
  required = false,
  color = blue,
}) => {
  return (
    <Container className={className}>
      <label htmlFor={label} style={{ flex: 1 }}>
        {label} {required && "*"}
      </label>
      <br />
      {required ? (
        <Input
          type={type}
          name={name}
          id={label}
          required
          color={color}
          autoCapitalize
        />
      ) : (
        <Input
          type={type}
          name={name}
          id={label}
          color={color}
          autoCapitalize="characters"
        />
      )}
    </Container>
  );
};

export const HorizontalInput = ({
  name,
  label,
  className = "",
  type = "text",
  required = false,
  color = blue,
}) => {
  return (
    <ContainerHorizontal className={className}>
      <label htmlFor={label}>
        {label} {required && "*"}
      </label>
      {required ? (
        <Input
          type={type}
          name={name}
          id={label}
          required
          color={color}
          autoCapitalize="characters"
        />
      ) : (
        <Input
          type={type}
          name={name}
          id={label}
          color={color}
          autoCapitalize="characters"
        />
      )}
    </ContainerHorizontal>
  );
};

export const Radio = ({
  name,
  label,
  className = "",
  color = blue,
  required = false,
  checked = false,
  onChange = () => {},
}) => {
  return (
    <Container className={className}>
      {required ? (
        <Input
          required
          type="radio"
          name={name}
          id={label}
          value={label}
          color={color}
          checked={checked}
          onChange={onChange}
        />
      ) : (
        <Input
          type="radio"
          name={name}
          id={label}
          value={label}
          color={color}
          style={{}}
          checked={checked}
          onChange={onChange}
        />
      )}
      <label htmlFor={label}>{label}</label>
    </Container>
  );
};

export const Checkbox = ({
  name,
  label,
  className = "",
  color = blue,
  required = false,
}) => {
  return (
    <Container className={className}>
      {required ? (
        <Input
          required
          type="checkbox"
          name={name}
          id={label}
          value={label}
          color={color}
        />
      ) : (
        <Input
          type="checkbox"
          name={name}
          id={label}
          value={label}
          color={color}
          style={{}}
        />
      )}
      <label htmlFor={label}>{label}</label>
    </Container>
  );
};

export const Other = ({
  name,
  color = blue,
  inputStyle = { flex: 4 },
  infoStyle = { flex: 1 },
  checked = false,
  onChange = () => {},
}) => {
  const ref = useRef(null);
  const id = `${name}Other`;

  useEffect(() => {
    if (!checked) ref.current.value = "";
  }, [checked]);

  return (
    <Container style={{ display: "flex" }}>
      <div style={infoStyle}>
        <Input
          type="radio"
          id={id}
          checked={checked}
          onChange={onChange}
          color={color}
        />
        <label htmlFor={id}>Otro:</label>
      </div>
      <Input
        ref={ref}
        type="text"
        name={`${name}.other_option_response`}
        disabled={!checked}
        color={color}
        style={inputStyle}
        autoCapitalize="characters"
      />
      {checked && (
        <Input
          type="text"
          name={name}
          value="__other_option__"
          style={{ display: "none" }}
        />
      )}
    </Container>
  );
};

export default {};
