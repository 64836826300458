export const rubros = [
  { label: "Gastronomía" },
  { label: "Comercio Minorista" },
  { label: "Comercio Mayorista" },
  { label: "Oficina Profesional" },
  { label: "Inmobiliaria / Oficina comercial" },
  { label: "Centro Médico / Consultorio" },
  { label: "Gimnasio" },
  { label: "Industria" },
  { label: "Deck en vereda exterior" },
  { label: "Toldo en vereda exterior" },
];

export const tipoDueno = [
  { label: "Dueño" },
  { label: "Alquilo" },
  { label: "Aún por alquilar" },
];

export const tipoPersona = [
  { label: "Persona física" },
  { label: "Persona jurídica" },
];

export const condicionLocal = [
  { label: "Terreno propio" },
  { label: "Propiedad Horizontal" },
  { label: "Shopping" },
  { label: "Galería comercial" },
];

export const ubicacionLocal = [
  { label: "Más de un Subsuelo" },
  { label: "Subsuelo" },
  { label: "Planta Baja" },
  { label: "Planta Primera" },
  { label: "Planta segunda o más" },
];

export const documentacion = {
  left: [
    { label: "Constancia de ABL (al día)" },
    { label: "Contrato de Locación" },
    { label: "Inscripción en AFIP + último pago" },
    { label: "Inscripción en IB + último pago" },
  ],
  right: [
    { label: "Estatuto o Contrato social." },
    { label: "Designaciones y Actualizaciones. Poderes." },
    { label: "Acreditación del Representante legal." },
    { label: "Copia DNI de los firmantes." },
    { label: "Plano de Antecedentes." },
  ],
};

export const formNames = {
  action: process.env.REACT_APP_HC_ACTION,
  nombreApellido: process.env.REACT_APP_HC_NOMBREAPELLIDO,
  mailContacto: process.env.REACT_APP_HC_MAILCONTACTO,
  numeroContacto: process.env.REACT_APP_HC_NUMEROCONTACTO,
  tipoPersona: process.env.REACT_APP_HC_TIPOPERSONA,
  barrio: process.env.REACT_APP_HC_BARRIO,
  direccion: process.env.REACT_APP_HC_DIRECCION,
  rubro: process.env.REACT_APP_HC_RUBRO,
  condicionLocal: process.env.REACT_APP_HC_CONDICIONLOCAL,
  poseePlanos: process.env.REACT_APP_HC_POSEEPLANOS,
  ubicacionLocal: process.env.REACT_APP_HC_UBICACIONLOCAL,
  tipoDueno: process.env.REACT_APP_HC_TIPODUENO,
  documentacion: process.env.REACT_APP_HC_DOCUMENTACION,
  mts2: process.env.REACT_APP_HC_MTS2,
};
