import styled from "styled-components";

const SocialItem = styled.div`
  display: grid;
  grid-template-columns: 30px auto;

  @media (max-width: 767px) {
    grid-template-columns: 20px 30px auto;
    grid-gap: 10px;
    grid-template-areas: ". img txt";
  }
`;

const SocialImage = styled.img`
  width: 25px;
  @media (max-width: 767px) {
    grid-area: img;
    width: 30px;
  }
`;
const SocialText = styled.span`
  ${(p) => (p.is ? "font-size: 16px" : "font-size: 0.6vw")}!important;
  ${(p) => (p.is ? "margin-top: 5px" : "margin-top: 7px")}!important;

  font-family: "MyriadPro";
  letter-spacing: 1px;

  @media (max-width: 767px) {
    grid-area: txt;
    ${(p) => (p.is ? "font-size: 16px" : "font-size: 14px")}!important;
    color: white;
  }
`;
export const Social = ({ src, text, href = "#", index = false }) => {
  return (
    <a
      href={href}
      target="_blank"
      style={{ color: "inherit", textDecoration: "inherit" }}
      rel="noreferrer"
    >
      <SocialItem is={index || ""}>
        <SocialImage src={src} alt="" />
        <SocialText>{text}</SocialText>
      </SocialItem>
    </a>
  );
};

export default {};
