import { Route, Switch } from "react-router-dom";
import Index from "../pages";
import HabilitacionComercial from "../pages/habilitacionComercial";

const Routes = () => (
  <Switch>
    <Route path="/" exact>
      <Index />
    </Route>
    <Route path="/habilitacion-comercial">
      <HabilitacionComercial />
    </Route>
  </Switch>
);
export default Routes;
