/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import backgroundImage from "../assets/imgs/portada.png";
import backgroundImageResponsive from "../assets/imgs/portada-responsive.svg";
import { blue, darkgrey, red } from "../components/globalStyles";
import { Social } from "../components/social";
import whatsapp from "../assets/imgs/whatsappBlue.svg";
import email from "../assets/imgs/email.svg";
import instagram from "../assets/imgs/instagram.svg";
import location from "../assets/imgs/location.svg";
import whatsappResponsive from "../assets/imgs/whatsapp-responsive.png";
import emailResponsive from "../assets/imgs/email-responsive.png";
import instagramResponsive from "../assets/imgs/instagram-responsive.png";
import "../assets/styles.css";

const Background = styled.div`
  background-image: url(${backgroundImage});
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center -65px;
  height: auto;

  @media (max-width: 767px) {
    background-image: url(${backgroundImageResponsive});
    background-position: center -0.5px;
  }
`;

const Button = styled.span`
  position: absolute;
  left: 41vw;
  top: 80vh;
  background-color: ${red};
  color: white;
  padding: 1vw;
  text-align: center;
  font-family: "MyriadPro";

  @media (max-width: 767px) {
    width: 50vw;
    background-color: ${darkgrey};
    padding: 10px;
    top: 90vh;
    left: 25vw;
  }
`;

const ContactContainer = styled.div`
  display: grid;
  grid-template-columns: auto 600px auto;
  grid-template-areas: ". main .";
  @media (max-width: 767px) {
    grid-template-columns: 20px auto 20px;
  }
`;

const SocialMain = styled.div`
  position: absolute;
  bottom: 4vh;
  left: 27vw;
  @media (max-width: 767px) {
    display: none;
  }
`;

const LocationMain = styled.div`
  position: absolute;
  top: 73vh;
  left: 42vw;
  font-size: 100px !important;
  color: white;
  @media (max-width: 767px) {
    display: none;
  }
`;

const SocialCotainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.2fr 1fr;
  grid-gap: 30px;
  color: white;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const Form = styled.form`
  grid-area: main;
  display: grid;
  background-color: ${darkgrey};
  padding: 20px;
  color: white;
  font-family: "MyriadPro";
  font-weight: bold;

  @media (max-width: 767px) {
    padding: 10px;
    margin-bottom: 50px;
  }
`;
const FormItem = styled.div`
  display: grid;
  grid-template-columns: 80px auto;
  margin-bottom: 10px;
  label {
    margin-top: 5px;
  }
  input,
  textarea {
    font-family: "MyriadPro";
    border: none;
    background-color: #404040;
    color: lightgrey;
    font-size: 17px;
  }

  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

const FormTitle = styled.h3`
  margin: 0;
  margin-bottom: 20px;
  font-family: "MyriadProLight";
  font-weight: 300;
  font-size: 28px;
  letter-spacing: 1.5px;

  @media (max-width: 767px) {
    font-size: 20px;
  }
`;

const FormButtonContainer = styled.div`
  text-align: right;
`;

const FormButton = styled.button`
  border: none;
  background-color: ${red};
  color: white;
  font-family: "MyriadPro";
  font-size: 17px;
  padding: 5px 40px;

  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

const Spacer = styled.div`
  height: 100vh;
  @media (max-width: 767px) {
    // height: 80vh;
  }
`;

const Index = () => {
  const [emailForm, setEmailForm] = useState({});
  const [submited, setSubmited] = useState();

  const sendEmail = () => {
    axios({
      method: "POST",
      url: "/mailer.php",
      headers: { "content-type": "application/json" },
      data: { ...emailForm, to: process.env.REACT_APP_EMAIL },
    })
      .then(() => {
        setSubmited("Correo enviado correctamente!");
      })
      .catch(() => {
        setSubmited("Ocurrio un error");
      });
  };

  return (
    <>
      <Background>
        <Spacer>
          <Link to="/habilitacion-comercial">
            <Button>
              <span>FORMULARIO</span>
              <br />
              <span>HABILITACIÓN COMERCIAL</span>
            </Button>
          </Link>
          <LocationMain>
            <Social
              index
              href="https://goo.gl/maps/swW4Muy3GnRFV29v5"
              src={location}
              text="AV. CORRIENTES 848, 3 306, CABA"
            />
          </LocationMain>
          <SocialMain>
            <SocialCotainer>
              <Social
                index
                href="https://www.instagram.com/ciudadintegralsoluciones/"
                src={instagram}
                text="@CIUDADINTEGRALSOLUCIONES"
              />
              <Social
                index
                href="mailto:SOLUCIONES@CIUDADINTEGRAL.COM.AR"
                src={email}
                text="SOLUCIONES@CIUDADINTEGRAL.COM.AR"
              />
              <Social
                index
                href="https://wa.me/5491138069769"
                src={whatsapp}
                text="11-3806-9769"
              />
            </SocialCotainer>
          </SocialMain>
        </Spacer>
        <ContactContainer>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              sendEmail();
            }}
          >
            <FormTitle>CONSULTAS</FormTitle>
            <FormItem>
              <label htmlFor="nombre">NOMBRE</label>
              <input
                id="nombre"
                required
                type="text"
                onChange={({ target: { value } }) => {
                  setEmailForm({ ...emailForm, name: value });
                }}
              />
            </FormItem>
            <FormItem>
              <label htmlFor="surname">APELLIDO</label>
              <input
                id="surname"
                required
                type="text"
                onChange={({ target: { value } }) => {
                  setEmailForm({ ...emailForm, surname: value });
                }}
              />
            </FormItem>
            <FormItem>
              <label htmlFor="email">EMAIL</label>
              <input
                id="email"
                required
                type="email"
                onChange={({ target: { value } }) => {
                  setEmailForm({ ...emailForm, email: value });
                }}
              />
            </FormItem>
            <FormItem>
              <label htmlFor="phone">CELULAR</label>
              <input
                id="phone"
                required
                type="tel"
                onChange={({ target: { value } }) => {
                  setEmailForm({ ...emailForm, phone: value });
                }}
              />
            </FormItem>
            <FormItem>
              <label htmlFor="question">CONSULTA</label>
              <textarea
                id="question"
                required
                onChange={({ target: { value } }) => {
                  setEmailForm({ ...emailForm, message: value });
                }}
              />
            </FormItem>
            <FormButtonContainer>
              <FormButton type="submit" disabled={submited}>
                Enviar
              </FormButton>
            </FormButtonContainer>
          </Form>
        </ContactContainer>
      </Background>
      {window.innerWidth < 768 && (
        <div style={{ backgroundColor: blue, padding: "20px 0" }}>
          <SocialCotainer>
            <Social
              index
              href="https://www.instagram.com/ciudadintegralsoluciones/"
              src={instagramResponsive}
              text="@CIUDADINTEGRALSOLUCIONES"
            />
            <Social
              index
              href="mailto:SOLUCIONES@CIUDADINTEGRAL.COM.AR"
              src={emailResponsive}
              text="SOLUCIONES@CIUDADINTEGRAL.COM.AR"
            />
            <Social
              index
              href="https://wa.me/5491138069769"
              src={whatsappResponsive}
              text="11-3806-9769"
            />
          </SocialCotainer>
        </div>
      )}
    </>
  );
};

export default Index;
